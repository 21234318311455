<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>{{ product.name | uppercase }}</h1>

        <span>
          <v-btn
            class="mr-3"
            color="warning"
            :to="{ name: 'product-form', params: { uuid } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="error"
            @click="handleDeleteModal"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <b>Nombre:</b> {{ product.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Servicio:</b> {{ product.service.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Clave:</b> {{ product.key }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Precio normal:</b> {{ product.unit_price_normal | currency }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Precio urgente:</b> {{ product.unit_price_urgent | currency }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Cantidad de prendas:</b> {{ product.clothes_quantity }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <Modal
      :show.sync="showDeleteModal"
      @submit="remove"
    >
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar el producto <b>{{ product.name | uppercase }}</b>?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// API
import ProductAPI from '@/api/product';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

export default {
  name: 'ProductDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  mixins: [
    DetailMixin,
  ],

  data: () => ({
    product: {
      service: {},
    },
    listRouteName: 'product-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.product.name || '';
      return [
        {
          text: 'Productos',
          disabled: false,
          exact: true,
          to: { name: 'product-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },
  },

  async created() {
    this.api = new ProductAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.product = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
